import { defineStore } from 'pinia';

// @ts-ignore
export const ActorStore = defineStore({
    id: 'actorStore',
    state: () => ({
        actors: {
            "nobody": "Noone"
        },
    }),
    actions: {
        setActors(actors: object) {
            // @ts-ignore
            this.actors = actors;
        },
        setActor(id: string, name:string) {
            // @ts-ignore
            this.actors[id] = name;
        },
        getActorById(id: string): string {
            // @ts-ignore
            return this.actors[id];
        },
    },
    getters: {
        getActors(): object {
            // @ts-ignore
            return this.actors;
        },
    },

    persist: {
        enabled: true,
            strategies: [
            {
                key: 'flxActors',
                storage: localStorage,
            },
        ],
    }
});
