import axios from "axios";
import localforage from "localforage";

import { ActorStore } from "../stores/actorStore";
import { DirectorStore } from "../stores/directorStore";
import { AssetStore } from "../stores/assetStore";
import { CategoriesStore } from "../stores/categoriesStore";
import { GenresStore } from "../stores/genresStore";
import { UserStore } from "../stores/userStore";
import { FavoriteStore } from "../stores/favoriteStore";
import { ProfileStore } from "../stores/profileStore";

interface ApiResponse {
  data: any;
  error: string | null;
  isCached: boolean;
}

interface AxiosResponse<T = any> {
  data: T;
  status: number;
  statusText: string;
  headers: any;
  config: any;
  request?: any;
}

interface CancelToken {
  promise: Promise<Cancel>;
  reason?: Cancel;
  throwIfRequested: () => void;
}

interface Canceler {
  (message?: string): void;
}

interface CancelTokenSource {
  token: CancelToken;
  cancel: Canceler;
}

interface Cancel {
  message?: string;
}

const CACHE_EXPIRATION_TIME = 2 * 60 * 1000; // 2 horas en milisegundos

class TimestampedData {
  data: any;
  timestamp: number;
  language: string;

  constructor(data: any, timestamp: number, language: string) {
    this.data = data;
    this.timestamp = timestamp;
    this.language = language;
  }
}

const do_not_cache = [
  "profiles",
  "v3/live",
  "v3/favorites",
  "apple/login",
  "v3/asset",
  "login",
];

class profile {
  profile: string;
  name: string;
  is_kids: boolean;

  constructor(profile: any) {
    this.profile = profile.profile;
    this.name = profile.name;
    this.is_kids = profile.is_kids === "true";
  }
}

export class FlixlatinoApiClient {
  private actorsStore: any;
  private directorStore: any;
  private assetsStore: any;
  private categoriesStore: any;
  private genresStore: any;
  private userStore: any;
  private favoriteStore: any;
  private profileStore: any;
  setup() {
    this.actorsStore = ActorStore();
    this.directorStore = DirectorStore();
    this.assetsStore = AssetStore();
    this.categoriesStore = CategoriesStore();
    this.genresStore = GenresStore();
    this.userStore = UserStore();
    this.favoriteStore = FavoriteStore();
    this.profileStore = ProfileStore();
  }

  private cancelTokenSource: CancelTokenSource | null = null;

  constructor() {
    this.setup();
    if (this.userStore.getToken) {
      axios.defaults.params = {
        token: this.userStore.getToken,
        profile: this.profileStore.getCurrentProfile.profile ?? 0,
        lang: this.userStore.selectedLanguage,
      };
    }
  }

  async get(
    endpoint: string,
    lang: string | null = null
  ): Promise<ApiResponse> {
    console.log("endpoint xx", endpoint, "with lang", lang);
    endpoint = "api/?l=" + endpoint;

    if (this.userStore.getToken) {
      axios.defaults.params.token = this.userStore.getToken;
      axios.defaults.params.profile =
        this.profileStore.getCurrentProfile.profile ?? 0;
    }
    axios.defaults.params.lang = this.userStore.selectedLanguage;
    // @ts-ignore
    axios.defaults.params.clarity_user_id = window.clarity_user_id ?? "";

    lang = lang ?? axios.defaults.params["lang"];
    const extras =
      "&lang=" + lang + "&profile=" + axios.defaults.params["profile"];

    axios.defaults.params["lang"] = lang;

    try {
      if (
        !do_not_cache.some((prefix) => endpoint.startsWith("api/?l=" + prefix))
      ) {
        const cachedData: TimestampedData = (await localforage.getItem(
          endpoint + extras
        )) as TimestampedData;
        if (
          cachedData !== null &&
          Date.now() - cachedData.timestamp < CACHE_EXPIRATION_TIME &&
          cachedData.language === lang
        ) {
          console.log("cachedData", cachedData);
          return {
            data: cachedData.data,
            error: null,
            isCached: true,
          };
        }
      }

      this.cancelTokenSource = axios.CancelToken.source();
      const response: AxiosResponse = await axios.get(endpoint, {
        // @ts-ignore
        cancelToken: this.cancelTokenSource.token,
      });

      const store = {
        data: response.data,
        timestamp: Date.now(),
        language: axios.defaults.params["lang"],
      };
      if (response.data.status !== "error") {
        await localforage.setItem(endpoint + extras, store, (err) => {
          if (err) {
            console.error(err);
          }
        });
      }

      return {
        data: response.data,
        error: null,
        isCached: false,
      };
    } catch (error) {
      if (axios.isCancel(error)) {
        return {
          data: null,
          error: "Request canceled",
          isCached: false,
        };
      }

      return {
        data: null,
        // @ts-ignore
        error: error.message,
        isCached: false,
      };
    }
  }

  async post(endpoint: string, data: any): Promise<ApiResponse> {
    endpoint = "api/?l=" + endpoint;

    // @ts-ignore
    if (window.clarity_user_id !== undefined) {
      if (endpoint.includes("?")) {
        // @ts-ignore
        endpoint += "&clarity_user_id=" + window.clarity_user_id;
      } else {
        // @ts-ignore
        endpoint += "?clarity_user_id=" + window.clarity_user_id;
      }
    }

    try {
      const response: AxiosResponse = await axios.post(endpoint, data);
      return {
        data: response.data,
        error: null,
        isCached: false,
      };
    } catch (error) {
      return {
        data: null,
        // @ts-ignore
        error: error.message,
        isCached: false,
      };
    }
  }

  async getProfiles(): Promise<ApiResponse> {
    return await this.get("profiles").then((response) => response.data);
  }

  async saveProfile(profile: any): Promise<ApiResponse> {
    return await this.post("profiles/handle", profile)
      .then((response) => response.data)
      .then((response) => response.profiles.all);
  }

  cancelRequest() {
    if (this.cancelTokenSource) {
      this.cancelTokenSource.cancel("Request canceled by user");
    }
  }
  async getAssetData(id: string, lang: string | null): Promise<ApiResponse> {
    const endpoint = "v3/asset" + "&id=" + id;
    return this.get(endpoint, lang).then((r) => {
      this.assetsStore.setAsset(id, r.data);
      return r;
    });
  }
  async getInitData(
    profile: object,
    lang: string | null
  ): Promise<ApiResponse> {
    this.profileStore.setCurrentProfile(profile);

    const kEndpoint = "kids_v3/init";
    const aEndpoint = "ios_v3/init_split";
    // @ts-ignore
    const endpoint = profile.is_kid === "true" ? kEndpoint : aEndpoint;
    // @ts-ignore

    return this.get(endpoint, lang).then((r) => {
      const response = r.data;
      // console.log("isCached", r.isCached);
      // console.log("assets", Object.keys(assetsStore.getAssets).length);

      this.actorsStore.setActors(response.actors);
      this.assetsStore.setAssets(response.movies, response.lang);
      this.categoriesStore.setCategories(response.categories, response.lang);
      this.categoriesStore.setFeatured(response.featured);
      this.genresStore.setGenres(response.genres);
      this.directorStore.setDirectors(response.directors);

      this.favoriteStore.setFavorites(response.favorites[0].movies);

      return response;
    });
  }

  async linkDevice(code: string): Promise<ApiResponse> {
    return this.post("link/link", {
      code: code,
    }).then((response) => response.data);
  }
  async cancelAccount(reasons: any): Promise<boolean> {
    return this.post("update", {
      cancelsubcription: true,
      reason: reasons,
    }).then((response) => {
      if (response.data.update.cancelsubcription) {
        this.userStore.setAccountStatus("cancelled");
      }

      return response.data.update.cancelsubcription;
    });
  }
  async changePassword(
    oldPassword: string,
    newPassword: string
  ): Promise<boolean> {
    console.info("change PAss", oldPassword, newPassword);
    return this.post("update", {
      oldpass: oldPassword,
      newpass: newPassword,
    }).then((response) => {
      if (response.data.status === "failure") {
        return false;
      }
      return true;
    });
  }

  async getFavorites(): Promise<ApiResponse> {
    return this.get("v3/favorites").then((response) => {
      this.favoriteStore.setFavorites(response.data.favorites);
      console.log("favorites", response.data.favorites);
      return response.data;
    });
  }

  async getLiveChannels(): Promise<ApiResponse> {
    return this.get("v3/live&nolimit=1").then((response) => response.data);
  }

  async getAssetBySlug(slug: string): Promise<ApiResponse> {
    return this.get("asset/" + slug).then((r) => {
      const response = r.data;
      this.assetsStore.setAsset(slug, response.movies);

      return response;
    });
  }
  async removeFavorite(id: string, profile: string): Promise<ApiResponse> {
    // @ts-ignore
    const payload = {
      id: id,
      profile: profile,
      remove: true,
    };
    return this.post("favorite", payload).then((response) => response.data);
  }

  async addFavorite(id: string, profile: string): Promise<ApiResponse> {
    // @ts-ignore
    const payload = {
      id: id,
      profile: profile,
    };
    return this.post("favorite", payload).then((response) => response.data);
  }

  async reportTime(profile: any): Promise<ApiResponse> {
    return await this.post("profiles/handle", profile)
      .then((response) => response.data)
      .then((response) => response.profiles.all);
  }

  async getAccount(): Promise<ApiResponse> {
    return this.get("v3/account").then((response) => {
      this.userStore.setAccount(response.data.account);
      return response.data;
    });
  }

  async braintreeUpgrade(action: string): Promise<ApiResponse> {
    const options = {
      action: action,
    };
    return this.post("braintree/upgrade", options).then((response) => {
      return response.data;
    });
  }
}
